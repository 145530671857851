import { Badge, VStack } from "@chakra-ui/react";
import React, { FC, PropsWithChildren, useContext } from "react";

import { StatemachineContext } from "./contexts/StatemachineContext";

const StatemachineWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { testMode } = useContext(StatemachineContext);

  return (
    <VStack
      alignItems="initial"
      background="background.footer"
      borderRadius="1em"
      height="100%"
      justify="center"
      paddingX="1em"
      position="relative"
      whiteSpace="normal"
      width="100%"
    >
      {children}
      {testMode && (
        <Badge
          colorScheme="green"
          marginTop="0"
          position="absolute"
          right={0}
          top={0}
        >
          Test modus
        </Badge>
      )}
    </VStack>
  );
};

export default StatemachineWrapper;
