import { chakra } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";

import { singularize } from "../../../../lib/string";
import Statemachine from "../../../statemachine";
import { Context } from "../../../statemachine/contexts/StatemachineContext";
import {
  ExtensionType,
  HTMLParser,
  MarkedExtension,
  RenderElement,
  StatemachineNode,
  Tokens,
} from "../types";

const REGEX =
  /^<stm(\s*buttonSubText="(?<buttonSubText>[^"]*)")?(\s*buttonText="(?<buttonText>[^"]*)")?(\s*id="(?<id>[^"]*)")?(\s*tagline="(?<tagline>[^"]*)")?><\/stm>/;

export const statemachineMarked: MarkedExtension = {
  level: "block",
  name: ExtensionType.statemachine,
  renderer(token): string {
    return token.raw;
  },
  start(src: string) {
    return src.match(REGEX)?.index;
  },
  tokenizer(src: string): void | Tokens.Generic {
    const match = src.match(REGEX);
    if (match) {
      return {
        buttonSubText: match.groups?.buttonSubText,
        buttonText: match.groups?.buttonText,
        id: match.groups?.id,
        raw: match[0],
        tagline: match.groups?.tagline,
        type: ExtensionType.statemachine,
      };
    }

    return undefined;
  },
};

export const parseStatemachineHTML: HTMLParser = (el) => ({
  buttonSubText: el.attribs["buttonsubtext"] ?? "",
  buttonText: el.attribs["buttontext"] ?? "",
  id: el.attribs["id"] ?? "",
  tagline: el.attribs["tagline"] ?? "",
  type: ExtensionType.statemachine,
});

export const renderStatemachine = (props: RenderElement) => {
  const location = useLocation();
  const { attributes, element } = props;
  const { children, ...statemachineProps } = element as StatemachineNode;
  const [_, resourceType, resourceId] = location.pathname.split("/");
  const referer = `${singularize(resourceType)}_${resourceId}_richtext_wz_${
    statemachineProps.id
  }`;

  return (
    <chakra.div {...props.attributes} marginBottom="2em">
      <Statemachine
        {...statemachineProps}
        context={Context.RichText}
        data-testid={
          (attributes as Record<string, string | boolean>)["data-testid"]
        }
        id={parseInt(statemachineProps.id)}
        referer={referer}
      />
    </chakra.div>
  );
};

export const statemachineMarkdown = (chunk: StatemachineNode) => {
  const { id, buttonSubText, buttonText, tagline } = chunk;

  return (
    "<stm " +
    `buttonSubText="${buttonSubText ?? ""}" ` +
    `buttonText="${buttonText ?? ""}" ` +
    `id="${id ?? ""}" ` +
    `tagline="${tagline ?? ""}"` +
    "></stm>\n"
  );
};
