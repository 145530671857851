import { Center, Heading, Icon, Link, Stack, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiRewind } from "react-icons/fi";

import useUpdateCallback from "../../hooks/useUpdateCallback";
import Button from "../button";

import Action from "./components/Action";
import Message from "./components/Message";
import { StatemachineContext } from "./contexts/StatemachineContext";
import useStartJourney from "./hooks/useStartJourney";
import { JourneyTrigger } from "./types";

const StatemachineBody = () => {
  const {
    buttonSubText,
    buttonText,
    currentJourney,
    error,
    getStep,
    loading,
    tagline,
    wizardId,
  } = useContext(StatemachineContext);
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(-1);
  const [updateJourney] = useUpdateCallback(
    currentJourney,
    GLOBALS.statemachineRoot
  );

  useEffect(() => {
    setStepIndex(
      (currentJourney?.relationships.current_steps.data ?? []).length - 1
    );
  }, [currentJourney?.id]);

  const currentStep = getStep(stepIndex);

  const submitButtonText = () => {
    if (error) {
      return t("shared:statemachine.tryAgain");
    } else if (buttonText) {
      return buttonText;
    } else {
      return t("shared:statemachine.defaultStartButton");
    }
  };

  const [startJourney] = useStartJourney(wizardId);
  const restartJourney = () => {
    if (updateJourney) {
      const handleSuccess = () => {
        setStepIndex(1);
      };
      updateJourney(
        {
          restart_count: (currentJourney?.attributes.restart_count ?? 0) + 1,
        },
        handleSuccess
      );
    } else {
      setStepIndex(1);
    }
  };

  if (currentStep) {
    return (
      <Stack
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        padding="2em"
      >
        <Message step={currentStep} />
        <Action
          step={currentStep}
          onSuccess={() => setStepIndex((prev) => prev + 1)}
        />
        {stepIndex > 1 && (
          <Link
            as="button"
            color="primary.darkActive"
            paddingTop={5}
            onClick={restartJourney}
          >
            <Icon
              as={FiRewind}
              marginRight="0.5em"
              verticalAlign="text-bottom"
            />
            {t("shared:statemachine.startAgain")}
          </Link>
        )}
      </Stack>
    );
  }

  return (
    <Center flexDirection="column" paddingY="2em">
      <Heading as="h2" marginBottom="1em" textAlign="center" variant="lg">
        {wizardId ? error ?? tagline : t("shared:statemachine.notFoundMessage")}
      </Heading>
      <Button
        flexDirection="column"
        isDisabled={!wizardId || loading}
        minWidth="10em"
        onClick={startJourney(JourneyTrigger.Initial)}
      >
        {submitButtonText()}
      </Button>
      <Text color="gray.main" fontSize="12px" textAlign="center">
        {buttonSubText}
      </Text>
    </Center>
  );
};

export default StatemachineBody;
