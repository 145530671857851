import React from "react";

import useCombinedReferer from "../../hooks/useCombinedReferer";

import StatemachineContextProvider, {
  StatemachineProps,
} from "./contexts/StatemachineContext";
import StatemachineBody from "./StatemachineBody";
import StatemachineWrapper from "./StatemachineWrapper";

const Statemachine = (props: StatemachineProps) => {
  const referer = useCombinedReferer(props.referer);

  return (
    <StatemachineContextProvider {...props} referer={referer}>
      <StatemachineWrapper>
        <StatemachineBody />
      </StatemachineWrapper>
    </StatemachineContextProvider>
  );
};

export default Statemachine;
